<template>
  <div>
    <v-sheet> </v-sheet>
    <v-container>
      <v-row justify="center" class="mt-4">
        <v-col cols="12" sm="12" xl="10">
          <v-row class="centered">
            <v-col cols="12" sm="12" md="6" v-if="!isShowRoomDirection()">
              <div class="store-info">
                <div class="store-text-bold">
                  Te invitamos a nuestra tienda
                </div>
                <div class="store-text">
                  Ubicada frente al Estadio Nacional, con facíl acceso y un
                  estacionamiento cómodo para todo tipo de vehículos
                </div>
                <div class="store-text-bold">
                  Av.Marathon 1315, Ñuñoa, Región metropolitana - Bodega 19
                </div>
                <div class="store-text">
                  Horarios de atención
                  <div class="store-text-bold">
                    Lunes a viernes: 9:00 - 17:00
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="12" sm="12" :md="isShowRoomDirection() ? 8 : 6">
              <div class="maps-banner">
                <div class="logo-row">
                  <v-img
                    style="cursor: pointer; max-width: 100px"
                    contain
                    @mouseover="megaMenu = false"
                    src="@/assets/img/logo_stadio-white.svg"
                    lazy-src="@/assets/img/logo_stadio.svg"
                  ></v-img>
                </div>
                <v-btn
                  dark
                  @click="openGoogleMaps"
                  style="border-width: medium"
                  rounded
                  class="btn-banner-logo"
                >
                  Abrir ubicación en
                  <span class="text-banner-bold"> Google Maps </span>
                </v-btn>
              </div>
              <iframe
                title="map"
                src="https://maps.google.com/maps?q=Edifier%20Chile%20Ltda&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed"
                width="100%"
                height="600"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <!-- CONFIRMATION MODEL -->
    <v-dialog v-model="confirmationDialog" width="600">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2 d-flex">
          ¡Felicitaciones!
          <v-icon
            class="ml-auto"
            @click="confirmationDialog = !confirmationDialog"
          >
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text class="pt-5">
          <p>Tu visita al Showroom fue exitosamente agendada.</p>
          <p>Enviamos a tu casilla los datos confirmándola.</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
// import moment from "moment";
import moment from "moment";
import VueMeetingSelector from "vue-meeting-selector";
// moment.tz.setDefault("Chile/Continental");

export default {
  name: "SimpleExample",
  components: {
    "vue-meeting-selector": VueMeetingSelector
  },
  data() {
    return {
      currentDate: moment().subtract(1, "days"),
      date: moment(),
      meetingsDays: [],
      meeting: [],
      loading: true,
      nbDaysToDisplay: 5,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      date_available: [],
      confirmationDialog: false,
      holidays_days: [
        "27-06",
        "15-08",
        "19-09",
        "10-10",
        "31-10",
        "01-11",
        "08-12",
        "07-06",
        "14-07",
        "13-07"
      ],

      count: 0
    };
  },
  async created() {
    window.fbq("trackCustom", "ShowRoomView");
    this.loading = true;
    await this.dateAvailable();
    this.loading = false;
  },
  computed: {
    // because of line-height, font-type you might need to change top value
    classNames() {
      return {
        tabLoading: "loading-div"
      };
    }
  },
  methods: {
    openGoogleMaps() {
      const url = " https://maps.app.goo.gl/Qy3Ey9zFapYZWYmMA";
      window.open(url, "_blank"); // Abre el URL en una nueva ventana o pestaña
    },

    isShowRoomDirection() {
      return this.$route.path.includes("/show_room/direction");
    },

    getMeetings(action) {
      const now = this.currentDate;

      let array_fecha = [];

      let valor = 6;
      Array.from({ length: 5 }, (_, index) => {
        let day;
        let status = false;
        let status_holidays_days = false;
        if (action === 1) {
          day = now.add(1, "days").format("YYYY-MM-DD 09:30:00");
        } else {
          day = now.subtract(1, "days").format("YYYY-MM-DD 09:30:00");
        }

        let date_name = moment(day).format("dddd");

        if (action === 1) {
          if (date_name == "Saturday") {
            day = now.add(2, "days").format("YYYY-MM-DD 09:30:00");
          }
          if (date_name == "Sunday") {
            day = now.add(1, "days").format("YYYY-MM-DD 09:30:00");
          }
        } else {
          if (date_name == "Saturday") {
            day = now.subtract(1, "days").format("YYYY-MM-DD 09:30:00");
          }
          if (date_name == "Sunday") {
            day = now.subtract(2, "days").format("YYYY-MM-DD 09:30:00");
          }
        }

        let value = 0;
        let slot = [];
        Array.from({ length: 13 }, (_, index) => {
          value = value + 30;
          const d = moment(day).add(value, "minutes");
          const now = d.toISOString(true);
          const utcNow = d.toISOString();

          status = this.date_available.some(index => {
            return index == utcNow;
          });

          if (!status) {
            status = this.holidays_days.some(index => {
              return index == moment(now).format("DD-MM");
            });
          }

          slot.push({ date: !status ? now : "" });
        });

        array_fecha.push({
          date: moment(day).toISOString(true),
          slots: slot
        });
      });

      array_fecha.sort(function(a, b) {
        if (a.date > b.date) {
          return 1;
        }
        if (a.date < b.date) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });

      return array_fecha;
    },

    nextDate() {
      this.loading = true;
      this.meetingsDays = this.getMeetings(1);
      this.date = new Date(
        moment(this.currentDate).format("YYYY-MM-DD 09:30:00")
      );
      this.loading = false;
    },

    previousDate() {
      this.loading = true;
      this.count++;

      if (this.count === 1) {
        this.currentDate = this.currentDate.subtract(4, "days");
      }

      this.meetingsDays = this.getMeetings(0);
      // this.date = new Date(moment(this.currentDate).format("YYYY-MM-DD 09:30:00"));
      this.loading = false;
    },

    async sendMeeting() {
      try {
        const request = {
          first_name: this.first_name,
          last_name: this.last_name,
          email: this.email,
          phone: this.phone,
          date: this.meeting.date,
          formatted_date: moment(this.meeting.date).format("YYYY-MM-DD HH:mm"),
          store_id: 7
        };
        await this.$store.dispatch("auth/SHOWROOM_REGISTER", request);
        this.$snotify.success("Registro Exitoso", "Exitos!");

        this.confirmationDialog = true;

        // await this.dateAvailable();

        // this.first_name = "";
        // this.last_name = "";
        // this.email = "";
        // this.phone = "";
        // this.meeting = [];

        // this.$refs.obs.clear()
      } catch (error) {
        if (error.response.status == 412) {
          this.$snotify.error(error.response.data.errors, "Error!");
        } else {
          this.$snotify.error("Error al registrar la reunion", "Error!");
        }
      }
    },

    async dateAvailable() {
      try {
        const response = await this.$store.dispatch("auth/SHOWROOM_AVAILABLE");

        this.date_available = response.data.data;

        this.meetingsDays = this.getMeetings(1);
      } catch (error) {
        console.log("error consultando fechas", error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.simple-example {
  margin-top: 3em;

  &__meeting-selector {
    max-width: 542px;
  }
}

// since our scss is scoped we need to use ::v-deep
::v-deep .loading-div {
  top: 58px !important;
}

.color-text {
  color: #00a0e9;
  font-size: 1.2em;
}

.chip {
  background-color: #00a0e9;
  color: white;
  padding-top: 12px;
  padding-bottom: 12px;
  border-radius: 12px;
}

.shadow-1 {
  -webkit-box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 20px !important;
}

.centered {
  align-items: center;
  justify-content: center;
}

.edifier-logo {
  height: 28px;
  object-fit: cover;
}

.maps-banner {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  padding: 10px;
}

.logo-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.logo-text {
  color: white;
  font-size: 18px;
  margin-left: 8px;
  margin-top: 6px;
  font-weight: 500;
}

.btn-banner-logo {
  background-color: white !important;
  text-transform: inherit !important;
  color: black !important;
  height: 44px !important;
  font-size: 14px !important;
}

.text-banner-bold {
  font-weight: 600;
  margin-left: 3px;
}
.store-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 5% 10%;
}
.store-text-bold {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
}
.store-text {
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  margin: 32px 0;
}

@media only screen and (max-width: 420px) {
  .logo-text {
    font-size: 15px;
  }

  .btn-banner-logo {
    font-size: 13px !important;
    height: 36px !important;
    padding: 0 8px !important;
  }
}
</style>
