var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mega_menu animate__animated animate__fadeIn animate__faster",staticStyle:{"background-color":"#f8f8f8","height":"100erm"},on:{"mouseleave":function($event){return _vm.close()}}},[_c('vue-scroll',{attrs:{"ops":{
      bar: { background: '#AF2641', keepShow: true }
    }}},[_c('v-container',[_c('v-row',{staticClass:"mb-10"},_vm._l((_vm.categories),function(category,index){return _c('v-col',{key:index,staticClass:"py-0",attrs:{"cols":"12","md":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var hover = ref.hover;
return [_c('div',{staticClass:"mt-5 mb-3 text-capitalize",style:(hover
                  ? 'font-size: 1.1em; cursor: pointer; color: rgb(110, 48, 232)'
                  : 'font-size: 1.1em; cursor: pointer'),on:{"click":function($event){return _vm.HandlerGetPublicProducts(category, 1)}}},[_vm._v(" "+_vm._s(category.name)+" ")])]}}],null,true)}),(
              category.name != 'Línea S' &&
                category.name != 'Gaming' &&
                category.name != 'Todo' &&
                category.name != 'Portátiles'
            )?_c('div',_vm._l((category.sub_category),function(sub_cat,j){return _c('div',{key:j,staticClass:"text-capitalize mb-1",staticStyle:{"font-size":"0.9em"}},[_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.HandlerGetPublicProducts(sub_cat, 2)}}},[_vm._v(" "+_vm._s(sub_cat.name)+" ")])])}),0):_vm._e()],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }